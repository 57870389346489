import "./App.css";
import {useMemo} from "react";

import Home from "./Home";

import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import * as anchor from "@project-serum/anchor";
import {clusterApiUrl} from "@solana/web3.js";
import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";
import {
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getSolletWallet,
    getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
    ConnectionProvider,
    WalletProvider,
} from "@solana/wallet-adapter-react";

import {WalletDialogProvider} from "@solana/wallet-adapter-material-ui";
import {createTheme, /*Switch,*/ ThemeProvider} from "@material-ui/core";

const treasury = new anchor.web3.PublicKey(
    process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
    process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
    process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
    palette: {
        type: 'dark',
    },
    overrides: {
        MuiButtonBase: {
            root: {
                justifyContent: 'flex-start',
            },
        },
        MuiButton: {
            root: {
                textTransform: undefined,
                padding: '12px 16px',
            },
            startIcon: {
                marginRight: 8,
            },
            endIcon: {
                marginLeft: 8,
            },
        },
    },
});

const App = () => {
    const endpoint = useMemo(() => clusterApiUrl(network), []);

    const wallets = useMemo(
        () => [
            getPhantomWallet(),
            getSlopeWallet(),
            getSolflareWallet(),
            getSolletWallet({network}),
            getSolletExtensionWallet({network})
        ],
        []
    );

    return (
        <ThemeProvider theme={theme}>
            <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect={true}>
                    <WalletDialogProvider>
                        <BrowserRouter>
                            <Switch>
                                <Route path="/mint2" render={
                                    () =>
                                        <Home
                                            candyMachineId={candyMachineId}
                                            config={config}
                                            connection={connection}
                                            startDate={startDateSeed}
                                            treasury={treasury}
                                            txTimeout={txTimeout}
                                        />}/>
                                <Route path="/faq" render={() => <Redirect to={{pathname: "/dickies/faq.html"}}/>}/>
                                <Route path="/index" render={() => <Redirect to={{pathname: "/dickies/index.html"}}/>}/>
                                <Route path="/hidden"
                                       render={() => <Redirect to={{pathname: "/dickies/hidden.html"}}/>}/>
                                <Route><Redirect from="/" to="/dickies/index.html"/></Route>
                            </Switch>
                        </BrowserRouter>
                    </WalletDialogProvider>
                </WalletProvider>
            </ConnectionProvider>
        </ThemeProvider>
    );
};

export default App;
