import React from 'react'
import {AnchorWallet} from "@solana/wallet-adapter-react";
import Alert from "@material-ui/lab/Alert";
import Countdown from "react-countdown";

import {Button, CircularProgress, Snackbar /*, Switch*/} from "@material-ui/core";
import {WalletDialogButton} from "@solana/wallet-adapter-material-ui";
import styled from "styled-components";

const ConnectButton = styled(WalletDialogButton)``;
const CounterText = styled.span``; // add your styles here
const MintContainer = styled.div``; // add your styles here
const MintButton = styled(Button)``; // add your styles here

interface AlertState {
    open: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error" | undefined;
}

interface PageMintProps {
    title: string
    wallet: AnchorWallet | undefined
    balance: number | undefined
    isActive: boolean
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>
    isSoldOut: boolean
    isMinting: boolean
    itemsAvailable: number
    itemsRedeemed: number
    itemsRemaining: number
    startDate: Date
    alertState: AlertState
    setAlertState: React.Dispatch<React.SetStateAction<AlertState>>
    shortenAddress: (address: string, chars?: number) => string
    onMint: () => Promise<void>
}

interface PageMintState {
    title: string

}

const renderCounter = ({days, hours, minutes, seconds, completed}: any) => {
    return (
        <CounterText>
            {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
        </CounterText>
    );
};

class PageMint extends React.Component<PageMintProps, PageMintState> {
    constructor(props: PageMintProps) {
        super(props)
        this.state = {title: props.title}
    }

    render() {
        return (
            <div>
                <br/>
                <h1>{this.state.title}</h1>
                <table>
                    {this.props.wallet && <tr>
                        <td>Wallet</td>
                        <td>{this.props.shortenAddress(this.props.wallet.publicKey.toBase58() || "")}</td><br/><br/>
                    </tr>}
                    {this.props.wallet && <tr>
                        <td>Balance</td>
                        <td>{(this.props.balance || 0).toLocaleString()} SOL</td><br/><br/>
                    </tr>}
                    {this.props.wallet && <tr>
                        <td>Total Available</td>
                        <td>{this.props.itemsAvailable} NFT</td><br/><br/>
                    </tr>}
                    {this.props.wallet && <tr>
                        <td>Total Redeemed</td>
                        <td>{this.props.itemsRedeemed} NFT</td><br/><br/>
                    </tr>}
                    {this.props.wallet && <tr>
                        <td>Total Remaining</td>
                        <td>{this.props.itemsRemaining} NFT</td><br/><br/>
                    </tr>}

                    {this.props.wallet && <tr><br/><br/></tr>}

                </table>

                <h3>Current Mint price 0.2069 SOL (Phase 1 pre-sale)</h3>

                <MintContainer>
                    {!this.props.wallet ? (
                        <ConnectButton>Connect your wallet to start</ConnectButton>
                    ) : (
                        <MintButton
                            disabled={this.props.isSoldOut || this.props.isMinting || !this.props.isActive}
                            onClick={this.props.onMint}
                            variant="contained"
                        >
                            {this.props.isSoldOut ? (
                                "SOLD OUT"
                            ) : this.props.isActive ? (
                                this.props.isMinting ? (
                                    <CircularProgress/>
                                ) : (
                                    "MINT"
                                )
                            ) : (
                                <Countdown
                                    date={this.props.startDate}
                                    onMount={({completed}) => completed && this.props.setIsActive(true)}
                                    onComplete={() => this.props.setIsActive(true)}
                                    renderer={renderCounter}
                                />
                            )}
                        </MintButton>
                    )}
                </MintContainer>

                <Snackbar
                    open={this.props.alertState.open}
                    autoHideDuration={6000}
                    onClose={() => this.props.setAlertState({...this.props.alertState, open: false})}
                >
                    <Alert
                        onClose={() => this.props.setAlertState({...this.props.alertState, open: false})}
                        severity={this.props.alertState.severity}
                    >
                        {this.props.alertState.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default PageMint